export const API = 'API';
export const SET_USER_INFO = 'SET_USER_INFO';

export const BASE_URL = 'https://canterasanjose.com/volconsoft/api/';
export const SITE_URL = 'https://canterasanjose.com/volconsoft/';

//export const BASE_URL = 'https://demo.canterasanjose.com/volconsoft/api/';
//export const SITE_URL = 'https://demo.canterasanjose.com/volconsoft/';

//export const BASE_URL = 'http://localhost:8074/cantera/backend/public/api/';
//export const SITE_URL = 'http://localhost:8074/cantera/backend/public/';
